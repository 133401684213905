<template>
    <div>
        <surround-loader :is-loading="isLoading">
            <div class="p-4">
                <h2 class="mb-4">Company details</h2>

                <b-form-group
                        label-size="sm"
                        label="Company name: *">
                    <b-form-input size="sm" v-model="formData.name"></b-form-input>
                    <error-display v-model="errors" ident="name"></error-display>
                </b-form-group>
                <div class="row ">
                    <div class="col-6">
                        <b-form-group
                                label-size="sm"
                                label="Address line 1: *">
                            <b-form-input size="sm" v-model="formData.address_line_1"></b-form-input>
                            <error-display v-model="errors" ident="address_line_1"></error-display>
                        </b-form-group>
                    </div>
                    <div class="col-6">
                        <b-form-group
                                label-size="sm"
                                label="Address line 2:">
                            <b-form-input size="sm" v-model="formData.address_line_2"></b-form-input>
                            <error-display v-model="errors" ident="address_line_2"></error-display>
                        </b-form-group>
                    </div>
                    <div class="col-6">
                        <b-form-group
                                label-size="sm"
                                label="Town: *">
                            <b-form-input size="sm" v-model="formData.town"></b-form-input>
                            <error-display v-model="errors" ident="town"></error-display>
                        </b-form-group>
                    </div>
                    <div class="col-6">
                        <b-form-group
                                label-size="sm"
                                label="Postcode: *">
                            <b-form-input size="sm" v-model="formData.post_code"></b-form-input>
                            <error-display v-model="errors" ident="post_code"></error-display>
                        </b-form-group>
                    </div>
                    <div class="col-6">
                        <b-form-group
                                label-size="sm"
                                label="Key contact email address: *">
                            <b-form-input size="sm" v-model="formData.notification_email"></b-form-input>
                            <error-display v-model="errors" ident="notification_email"></error-display>
                        </b-form-group>
                    </div>
                    <div class="col-6">
                        <b-form-group
                                label-size="sm"
                                label="Key contact telephone number:">
                            <b-form-input size="sm" v-model="formData.phone_number"></b-form-input>
                            <error-display v-model="errors" ident="phone_number"></error-display>
                        </b-form-group>
                    </div>

                    <div class="col-6">
                        <b-form-group
                                label-size="sm"
                                label="Accounts payable contact name: *">
                            <b-form-input size="sm" v-model="formData.accounts_name"></b-form-input>
                            <error-display v-model="errors" ident="accounts_name"></error-display>
                        </b-form-group>
                    </div>
                    <div class="col-6">
                        <b-form-group
                                label-size="sm"
                                label="Accounts payable email address: *">
                            <b-form-input size="sm" v-model="formData.accounts_email"></b-form-input>
                            <error-display v-model="errors" ident="accounts_email"></error-display>
                        </b-form-group>
                    </div>

                    <div class="col-12" style="font-size:14px;">
                        * denotes required fields
                    </div>

                </div>
            </div>
            <div class="p-4  d-flex justify-content-between">
                <b-button :to="{name:'setup_welcome'}" variant="outline-secondary">
                    Back
                </b-button>

                <b-button @click="saveForm" variant="success">
                    Proceed to system setup
                </b-button>
            </div>
        </surround-loader>
    </div>
</template>

<script>
    import Vue from "vue";
    import SurroundLoader from "../SurroundLoader";
    import {getResource, saveResource} from "../../modules/api/methods";
    import {
        organisationCurrent,
        organisationCurrentSetup,
        organisationSetupCompanyDetails
    } from "../../modules/api/endpoints";
    import ErrorDisplay from "../form_inputs/ErrorDisplay";
    import {containsErrors, isEmail} from "../../modules/helpers/helpers";

    export default {
        name: 'setup_company_details',
        data: function () {
            return {
                isLoading: false,
                formData: {},
                errors: {}
            }
        },
        created() {
            if(this.$store.state.user.organisation != null) {
                this.loadModel();
            } else {
                if(this.formData.notification_email == null)
                this.formData.notification_email = this.$store.state.user.email;
            }
        },
        methods: {
            loadModel() {
                this.isLoading = true;
                getResource(organisationCurrent).then((resp) => {
                    var data = resp.data.success.data;
                    this.formData = data;
                    if(this.formData.notification_email == null || !isEmail(this.formData.notification_email)) {
                        this.formData.notification_email = this.$store.state.user.email;
                    }
                    this.isLoading = false;
                }).catch((ex) => {
                    if(this.formData.notification_email == null || !isEmail(this.formData.notification_email)) {
                        this.formData.notification_email = this.$store.state.user.email;
                    }
                    console.log(ex);
                    this.isLoading = false;
                });
            },
            saveForm() {
                var vm = this;
                vm.errors = {};
                vm.isLoading = true;
                saveResource(organisationSetupCompanyDetails, vm.formData).then(function (resp) {
                    console.log(resp);
                    vm.$store.dispatch('updateOrganisation', resp.data.success.data).then(() => {
                        vm.$router.push({name: 'setup_system_setup',query: {session_organisation_id: resp.data.success.data.id}}).catch(error => {});
                    });
                }).catch(function (err) {
                    console.log(err);
                    if (containsErrors(err)) {
                        vm.errors = err.data.errors;
                    }
                }).finally(function () {
                    vm.isLoading = false;
                })
            }
        },
        components: {ErrorDisplay, SurroundLoader},
    }
</script>

